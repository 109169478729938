#hero {
    height: 320px;
    display: flex;
    flex-direction: column;
}
.fix-nav {
  position: relative;
  top:200px;
}

#catcher {
    font-weight: 700;  
    text-align: center;
    margin-top: 20px;
}
.logo-small {
 height: 32px;
}

h1, 
.ia-blue {
  color: #6268a8 !important;
}

#lng-sel-row {
  line-height: 50px !important ;
}

.fillvh-not-used {
  min-height: calc(100vh - 50px - 100px);
}

#footer-nav {
margin-top: auto;

}